<template>
    <PromiseContainer :promises.sync="promises">
        <h1>Consultatie uren</h1>
        <v-switch dense v-model="showInActive">
          <template v-slot:label>
            <span>Toon inactieve opties</span>
            <BasicToolTip description="Enkel toegepast op Klant/Persoon/Project dropdowns, geen effect op gegeven data" />
          </template>
        </v-switch>
        
        <v-form v-model="valid">
            <v-row dense>
                <v-col cols="12" md="6">
                    <label>Consult</label>
                    <v-autocomplete v-if="hasClientProjectmanagement" v-model="consultValue" :items="getConsultOptions"
                              label="Optie"
                              @change="reset" :rules="consultRules" autofocus>
                    </v-autocomplete>
                  <p v-else>Personen</p>
                </v-col>
            </v-row>
            <v-row dense>
                <CustomClientAndProjectSelect v-if="consultValue === 'Klant'" v-model="clientAndProject" :all="true"
                cols="12" md="6" :showInActive="showInActive"/>
            </v-row>
            <CustomUserSelect dense v-if="consultValue === 'Personen'" :all="true" v-model="user" tabindex="2" :autoFillUserId="getUserSettingFromLocalStorage" @input="event => setUserSettingInLocalStorage(event)"
              cols="6" :showInActive="showInActive"/>
            <v-row dense>
                <v-col>
                    <v-autocomplete dense v-model="selectedDateOption" :items="dateOptions" outlined
                                @change="selectDateOption"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row dense no-gutters justify="start">
                <v-col cols>
                    <DatePicker :label="startDateLabel" v-model="startDate" :key="startDate" tabindex="4"/>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols>
                    <DatePicker :label="endDateLabel" v-model="endDate" :key="endDate"
                                tabindex="5" :min="new Date(startDate)"/>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" dense>
                <v-col cols="12">
                    <v-btn type="button" color="secondary" class="mx-auto mb-2" :disabled="!valid"
                            @click="showData">
                        Toon data
                    </v-btn>
                    <v-btn type="button" color="secondary" class="mx-auto mb-2" :disabled="!valid"
                            @click="requestExport">
                        Exporteer data
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-data-table v-if="showTable"
                        :headers="headers"
                        :items="tableData"
                        class="elevation-1 mt-4"
                        :sort-by.sync="sortBy"
                        :no-data-text="'Geen resultaat beschikbaar'"
                        :hide-default-footer="hideFooter"
                        :custom-sort="sortNullLast"
                        must-sort>
        </v-data-table>
    </PromiseContainer>
</template>

<script>
import CustomClientAndProjectSelect from "../components/shared/CustomClientAndProjectSelect.vue"
import CustomUserSelect from "@/components/shared/CustomUserSelect.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import {getRequiredRules} from "@/shared/utils/inputRulesUtils.js"
import {
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import parse from "date-fns/parse";
import {sortByDate} from "@/shared/utils/dateUtils";
import {analyticsEvent, EventCategory} from "@/shared/utils/gtagUtils";
import {organizationHasModule} from "@/shared/utils/authenticationUtils";
import ModuleType from "@/shared/enums/moduleType";
import BasicToolTip from "@/components/shared/actions/BasicToolTip"

export default {
    name: "HourConsultationOverview.vue",
    components: {
        DatePicker,
        CustomUserSelect,
        CustomClientAndProjectSelect,
        PromiseContainer,
        BasicToolTip,
    },
    async created() {
        this.fetchOtherSettingsFromLocalStorage();
    },
  watch: {
      consultValue(newValue) {
        if(newValue)
            localStorage.setItem(this.localStoragePrefix + 'consultValue', newValue);
      },
      clientAndProject(newValue) {
        if(newValue) {
        localStorage.setItem(this.localStoragePrefix + 'clientId', newValue.client.id);
          if (newValue.project) {
            localStorage.setItem(this.localStoragePrefix + 'projectId', newValue.project.id);
          }
        }
      },
    },
    data() {
        return {
            valid: false,
            showInActive: false,
            error: null,
            consultValueData: null,
            clientAndProject: {
                client: null,
                project: null,
            },
            user: null,
            startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
            endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
            startDateLabel: "Begin datum",
            endDateLabel: "Eind datum",
            consultRules: getRequiredRules("Optie uren per klant of per persoon moet gekozen worden"),
            endDateRules: [
                v => !!v || "Datum moet ingevuld worden",
                v => (!!v && this.startDate <= this.endDate) || 'Datum moet na begindatum zijn.'],
            showTable: false,
            dataTable: [],
            headers: null,
            tableData: [],
            dateOptions: ['Deze maand', 'Vorige maand', 'Deze week', 'Vorige week', 'Dit jaar', 'Vorig jaar'],
            selectedDateOption: 'Deze maand',
            sortBy: 'name',
            hideFooter: false,
            localStoragePrefix: 'report#',
            hasClientProjectmanagement: organizationHasModule([ModuleType.CLIENT_PROJECTMANAGEMENT]),
            promises: [
                this.$store.dispatch('clientsModule/fetchClientsThatHaveProjectsForOrganization')
                    .then(() => this.fetchClientAndProjectSettingsFromLocalStorage()),
            ]
        }
    },
    computed: {
        getUserSettingFromLocalStorage() {
            const storedUserId = localStorage.getItem(this.localStoragePrefix + 'personId')
            if (storedUserId && storedUserId !== 'undefined') {
                return storedUserId;
            }
            return null
        },
        getConsultOptions() {
            return this.hasClientProjectmanagement?['Klant', 'Personen']:['Personen']
        },
        consultValue: {
            set(newValue) {
                if(this.getConsultOptions.includes(newValue)) {
                    this.consultValueData = newValue
                } else {
                    this.consultValueData = this.getConsultOptions.at(0)
                }
            },
            get() {
                return this.consultValueData
            }
        }
    },
    methods: {
        setUserSettingInLocalStorage(newValue) {
            if(newValue)
                localStorage.setItem(this.localStoragePrefix + 'personId', newValue.id);
        },
        fetchClientAndProjectSettingsFromLocalStorage() {
            const storedClientId = localStorage.getItem(this.localStoragePrefix + 'clientId');
            const storedProjectId = localStorage.getItem(this.localStoragePrefix + 'projectId');
            if (storedClientId && storedClientId !== 'undefined') {
                this.clientAndProject.client = this.$store.state.clientsModule.clients.find(client => client.id === storedClientId);
                if (storedProjectId && storedProjectId !== 'undefined') {
                    this.clientAndProject.project = this.clientAndProject.client.projects.find(project => project.id === storedProjectId);
                }
            }
        },
        fetchOtherSettingsFromLocalStorage() {
            // fetch previously selected values from localStorage
            this.consultValue = localStorage.getItem(this.localStoragePrefix + 'consultValue');
            this.startDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');
            this.endDate = format(endOfMonth(new Date()), 'yyyy-MM-dd');
        },
        roundToTwoDecimalPoints(value) {
          return typeof value === 'number' ? value.toFixed(2) : value;
        },
        roundInnerObject(obj) {
          const updatedObj = {};
          for (let [key, value] of Object.entries(obj)) {
            updatedObj[key] = this.roundToTwoDecimalPoints(value);
          }
          return updatedObj;
        },
        getDayOfWeek(date) {
          // Add the day of the week in Dutch
          const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
          return new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(parsedDate);
        },
        requestExport() {
            const exportRequest = {
                clientId: this.clientAndProject?.client?.id,
                projectId: this.clientAndProject?.project?.id,
                userId: this.user?.id,
                startDate: subDays(new Date(this.startDate), 1),
                endDate: addDays(new Date(this.endDate), 1),
            }
            this.promises.push(
                this.$store.dispatch('fileExportModule/requestExport', exportRequest)
            )
        },
        showData() {
            analyticsEvent('hour_consultation', EventCategory.REPORTING)
            if (this.user) {
                this.handleUsers();
            } else {
                this.handleClients();
            }
        },
        handleUsers() {
            const userReportRequest = {
                userId: this.user ? this.user.id : null,
                startDate: subDays(new Date(this.startDate), 1),
                endDate: addDays(new Date(this.endDate), 1),
            }
            this.promises.push(
                this.$store.dispatch('consultHoursModule/getHoursUsers', userReportRequest)
                .then(result => {
                    if (!this.user.id) {
                        this.generateAndShowDetailedTable(result)
                    } else {
                        this.generateAndShowDetailedTableByDay(result)
                    }
                })
            )
        },
        handleClients() {
            const clientReportRequest = {
                    clientId: this.clientAndProject.client && this.clientAndProject.client.id != "All" ? this.clientAndProject.client.id : null,
                    projectId: this.clientAndProject.project && this.clientAndProject.project.id != "All" ? this.clientAndProject.project.id : null,
                    startDate: subDays(new Date(this.startDate), 1),
                    endDate: addDays(new Date(this.endDate), 1),
            }
            this.promises.push(
                this.$store.dispatch('consultHoursModule/getHoursClients', clientReportRequest)
                .then(result => this.generateAndShowHoursTable(result))
            )
        },
        generateAndShowHoursTable(result) {
            let convertedData = [];
            for (let [key, value] of Object.entries(result?.data)) {
                convertedData.push({name: key, hours: this.roundToTwoDecimalPoints(value)});
            }
            this.headers = [
                {
                    text: "Naam",
                    align: 'start',
                    sortable: true,
                    value: 'name'
                },
                {text: 'Uren', value: 'hours'},
            ];
            this.hideFooter = convertedData.length === 0
            this.sortBy = 'name'
            this.tableData = convertedData;
            this.showTable = true;
        },
        generateAndShowDetailedTable(result) {
            let convertedData = [];
            this.headers = [
                {
                    text: "Naam",
                    align: 'start',
                    sortable: true,
                    value: 'name'
                },
            ];

            for (let [personName, value] of Object.entries(result?.data)) {
                convertedData.push({name: personName, ...this.roundInnerObject(value)});
                // Loop over keys of the values, add these to headers
                // Example response: {Michael={Verlof=8.0, Werk=24.0}, Tom={Werk=8.0}}
                for (let key of Object.keys(value)) {
                    if (this.headers.filter(item => item.text === key).length === 0) {
                        this.headers.push({text: key, value: key})
                    }
                }
            }
            this.hideFooter = convertedData.length === 0
            this.sortBy = 'name'
            this.tableData = convertedData;
            this.showTable = true;
        },
        generateAndShowDetailedTableByDay(result) {
            if(!result || !result?.data || !Object.entries(result?.data).length){
              // rapport generated no results
              this.tableData = []
              this.headers = []
              this.showTable = true
              this.hideFooter = true
              return
            }
            let convertedData = [];
            this.headers = []

            // Add headers defined in backend (Datum, Klant, Project)
            const dynamicHeaderNames = Object.keys(result?.data.headers);
            for (let header of dynamicHeaderNames) {
              this.headers.push({
                text: header,
                value: header,
              })
              if (header === 'Datum') {
                this.headers.push({
                  text: 'Dag',
                  value: 'Dag',
                })
              }
            }

            for (let [date, value] of Object.entries(result?.data)) {
                // we don't need the header values in our table
                if (date !== "headers") {
                    /*var exampleResponse = {
                        "18/04/2023": {
                            "Ziekte": 8
                        },
                        "headers": {
                            "Datum": 0,
                            "Klant": 0,
                            "Project": 0
                        },
                        "17/04/2023|Buildbase|Development": {
                            "Werk": 8,
                            "Klant": "Buildbase",
                            "Project": "Development"
                        },
                        "17/04/2023|Strouwi BV|Opleiding": {
                            "Werk": 1,
                            "Klant": "Strouwi BV",
                            "Project": "Opleiding"
                        }
                    }*/
                    // split the date client and project from the date key
                    const dayClientProject = date.split("|")
                    for (let i = 0; i < dayClientProject.length; i++) {
                        // add the key (Datum, Klant or Project) and give it the correct value from the date-key ({Datum=17/04/2023} or {Klant=Buildbase})
                        const headerName = dynamicHeaderNames[i];
                        value[headerName] = dayClientProject[i]
                    }
                    // Round the hours to 2 decimals, add the day of the week and push the value to the convertedData array
                    const newValue = this.roundInnerObject(value)
                    newValue['Dag'] = this.getDayOfWeek(dayClientProject[0]);
                    convertedData.push(newValue)
                    // Loop over keys of the values, add these to headers
                    for (let key of Object.keys(newValue)) {
                        if (this.headers.filter(item => item.text === key).length === 0) {
                            // add rest of headers not defined in backend-headers (Werk, Verlof, Andere, ...)
                            this.headers.push({
                                text: key,
                                value: key,
                            })
                        }
                    }
                }
            }
            this.hideFooter = convertedData.length === 0
            this.sortBy = 'Datum'
            this.tableData = convertedData
            this.showTable = true
        },
        reset() {
            this.clientAndProject.client = null
            this.user = null
            this.clientAndProject.project = null
        },
        selectDateOption(dateOption) {
            this.selectedDateOption = dateOption
            switch (this.selectedDateOption) {
                case "Deze maand":
                    this.setThisMonth()
                    break
                case "Vorige maand":
                    this.setPreviousMonth()
                    break
                case "Deze week":
                    this.setThisWeek()
                    break
                case "Vorige week":
                    this.setPreviousWeek()
                    break
                case "Dit jaar":
                    this.setThisYear()
                    break
                case "Vorig jaar":
                    this.setPreviousYear()
            }
        },
        setThisWeek() {
            this.startDate = format(startOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd')
            this.endDate = format(endOfWeek(new Date(), {weekStartsOn: 1}), 'yyyy-MM-dd')
        },
        setPreviousWeek() {
            this.startDate = format(startOfWeek(subWeeks(new Date(), 1), {weekStartsOn: 1}), 'yyyy-MM-dd')
            this.endDate = format(endOfWeek(subWeeks(new Date(), 1), {weekStartsOn: 1}), 'yyyy-MM-dd')
        },
        setThisMonth() {
            this.startDate = format(startOfMonth(new Date()), 'yyyy-MM-dd')
            this.endDate = format(endOfMonth(new Date()), 'yyyy-MM-dd')
        },
        setPreviousMonth() {
            this.startDate = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
            this.endDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        },
        setThisYear() {
            this.startDate = format(startOfYear(new Date()), 'yyyy-MM-dd')
            this.endDate = format(endOfYear(new Date()), 'yyyy-MM-dd')
        },
        setPreviousYear() {
            this.startDate = format(startOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd')
            this.endDate = format(endOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd')
        },
        sortNullLast(items, index, isDesc) {
            items.sort((a, b) => {
                let c = a[index]
                let d = b[index]
                let cDate = parse(c, 'dd/MM/yyyy', new Date)
                let dDate = parse(d, 'dd/MM/yyyy', new Date)
                if (isValid(cDate)) {
                    return sortByDate(cDate, dDate, isDesc)
                }
                if (c === d) {
                    return 0
                } else if (typeof c === 'undefined') {
                    return 1
                } else if (typeof d === 'undefined') {
                    return -1
                } else if (!isDesc[0]) {
                    return c < d ? -1 : 1
                } else {
                    return c < d ? 1 : -1
                }
            })
            return items
        }
    }
}
</script>