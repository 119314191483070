<template>
  <v-tooltip z-index="2000" top>
    <template v-slot:activator="{ on }">
      <v-icon color="primary" v-on="on" x-small class="pb-2">mdi-information-outline</v-icon>
    </template>
    <span>{{description}}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "BasicToolTip",
  props:{
    description: null,
  },
  methods: {
    
  }
}
</script>